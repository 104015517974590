@import "../../styles/variables";

@value card from "../productCard/ProductCard.module.scss";

.container {
  border: 2px solid $secondary-color;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  .card {
    border: 0;
    width: 50%;
  }

  .ad {
    margin: auto 0;
    text-align: center;
    width: 50%;
  }
}
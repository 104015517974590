@import "../../styles/variables";

.container {
  align-items: center;
  display: flex;
  height: calc(100vh - #{$header-height});
  justify-content: center;

  :global(.MuiCircularProgress-root) {
    color: $secondary-color;
  }
}

.fullPage {
  background-color: $white;
  position: fixed;
  width: 100%;
  z-index: 10003;
}

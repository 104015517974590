@import "../../styles/variables";

.card {
  border: 1px solid $light-grey;
  border-radius: 2px;
  color: $black-secondary-color;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 8.75rem;
  padding: 16px 16px 32px;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 22px;
    font-weight: bold;
  }

  &__brand {
    font-size: 14px;
  }
}

.ad {
  text-align: center;
}

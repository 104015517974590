@use "../../../styles/mixins" as mixins;
@import "../../../styles/variables";

.container {
  @include mixins.clearedRichText;
  @include mixins.styledRichText;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.title {
  font-size: 22px;

  &:after {
    border-bottom: 4px solid $secondary-color;
    content: "";
    display: block;
    width: 2rem;
  }
}
$white: #FFF;
$light-grey: #D0D5DD;
$dark-blue: #14123B;
$red: #BD2624;

$black-primary-color: #1C2633;
$black-secondary-color: #1C2633;
$secondary-color: #257EA4;

/* TODO: Change active-link color after confirm */
$hovered-link: #1A3175;
$active-link: #1A3175;

$footer-primary: #444;
$footer-secondary: #656668;
$footer-text-secondary: #F0EFF4;

$icon-color: #8F929A;
$background-color: #F2F4F7;
$scroll-thumb-color: #C3C7CF;

$facebook: #415A93;
$twitter: rgba(82,82,82,0.8);
$linkedin: #3176B0;
$icon-button-shadow: #333;

$header-height: 5rem;
$border-height: 1px;
$footer-height: 518px;

$width-expanded: 1350px;
$width-collapsed: 1050px;

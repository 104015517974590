@use "../styles/mixins";
@import "../styles/variables";

.title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 2rem;
}

.container {
  &:global(.MuiContainer-root) {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
  }
}

.link {
  @include mixins.link;
}

.button:global(.MuiButton-root) {
  background-color: $secondary-color;
  border-radius: 1px;
  font: bold 16px "Open Sans", sans-serif;
  margin: 1rem 0;
  padding: 1rem;
  text-transform: none;
  width: fit-content;

  &:hover {
    background-color: $hovered-link;
  }

  &:active {
    background-color: $active-link;
  }

}

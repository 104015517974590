@use "../../styles/mixins" as mixins;
@import "../../styles/variables";

.button {
  &:global(.MuiButtonBase-root) {
    @include mixins.button;

    margin-bottom: 1rem;
    width: fit-content;
  }
}

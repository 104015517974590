@use "../../styles/mixins" as mixins;
@import "../../styles/variables";

%disabled {
  cursor: default;
  pointer-events: none;
}

.header {
  background-color: $white;
  border-bottom: $border-height solid $secondary-color;
  box-sizing: border-box;
  display: flex;
  height: $header-height;
  position: sticky;
  top: 0;
  z-index: 10002;
}

.logo {
  height: 34px;
  margin: 0 1rem;
}

.logoLink {
  align-self: center;

  &_disabled {
    @extend %disabled;
  }
}

.tabs {
  display: flex;
  gap: 4rem;
  margin: 2rem 0 0.875rem 10%;

  a {
    @include mixins.tab;
    @include mixins.disableHoverShifting;

    height: fit-content;
    white-space: nowrap;

    &.disabled {
      @extend %disabled;

      color: $light-grey;
    }

    &.selected {
      @include mixins.tabSelected;

      border-bottom: 4px solid $secondary-color;
      padding: 0 0.5rem 0.875rem;
    }
  }
}

.menu {
  &:global(.MuiPopover-root) {
    z-index: 10003;
  }

  :global(.MuiMenuItem-root) {
    color: $secondary-color;
    font-family: "Open Sans", sans-serif;
    padding: 9px 32px;

    &:hover {
      background-color: $background-color;
    }
  }
}

.menuContainer {
  align-self: center;
  margin: 0 2rem 0 auto;
}

.menuButton {
  &:global(.MuiButtonBase-root) {
    @include mixins.button;

    :global(.MuiButton-startIcon) {
      color: $icon-color;
    }
  }
}
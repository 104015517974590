@use "sass:color";
@import "../../styles/variables";

@mixin iconButton($color) {
  background-color: $color;

  &:hover {
    background-color: color.adjust($color, $lightness: -8%);
    box-shadow: 1px 1px 3px $icon-button-shadow;
  }
}

.main {
  background-color: $footer-primary;
}

.bottom {
  background-color: $footer-secondary;

  :global(.MuiContainer-root) {
    align-items: center;
    color: $footer-text-secondary;
    display: flex;
    font-size: 12px;
    min-height: 2.5rem
  }
}

.container {
  color: $white;
  line-height: 25px;
  min-height: $footer-height;
  position: relative;
  z-index: 10001;

  a,
  .linkBar a {
    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img {
    width: 50%;
  }
}

.row {
  color: $footer-text-secondary;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 2rem 0 3.25rem;

  > :first-child {
    width: 25%;
  }

  > :last-child {
    width: 70%
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  a {
    width: fit-content;
  }
}

.title {
  color: $white;
  cursor: default;
  font-size: 14px;
  font-weight: bold;
}

.linkBar {
  display: flex;

  div {
    width: 25%;
  }

  :first-child {
    a {
      color: $white;
      font-weight: bold;
    }
  }
}

.icons {
  display: flex;
  gap: 1.5rem;

  :global(.MuiButtonBase-root) {
    height: 40px;
    width: 40px;

    &.facebook {
      @include iconButton($facebook);
    }

    &.twitter {
      @include iconButton($twitter);
    }

    &.linkedIn {
      @include iconButton($linkedin);
    }
  }

  :global(.MuiSvgIcon-root) {
    fill: $white;
    font-size: 16px;
  }
}

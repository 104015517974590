@import "../../../styles/variables";

@mixin highlight-link($bg-color) {
  background-color: $bg-color;
  color: $white;
  font-weight: bold;
}

.link {
  align-items: center;
  color: $black-secondary-color;
  display: inline-flex;
  min-height: 36px;
  padding-left: 40px;

  &:hover:not(.link_active) {
    @include highlight-link($hovered-link);
  }

  &_active {
    @include highlight-link($secondary-color);
  }

  &_collapsible {
    font-size: 14px;
  }
}

@use "../../../styles/mixins";
@import "../../../styles/variables";

.list {
  margin: 0;
  padding-left: 1.2rem;

  a {
    @include mixins.link;
  }

  :global(.MuiSvgIcon-root) {
    color: $secondary-color;
    font-size: 1rem;
    margin-right: 0.25rem;
    vertical-align: middle;
  }
}

.grid {
  display: flex;
  gap: 1.5rem;
  width: auto;

  &_indented {
    margin-top: 1.5rem;
  }

  &_column {
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

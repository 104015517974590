@import "styles/variables";

html {
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;
  position: relative;
}

html,
body {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

div, input, p, i, a, h1, h2, h3, h4, h5, span, button {
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4 {
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.pageContainer {
  display: flex;
  position: relative;
}

.minContainer {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
}

.entityContainer {
  display: flex;
  flex-direction: column;

  .ad {
    text-align: center;

    iframe {
      margin-bottom: 1.5rem;
    }
  }
}

@import "../../../styles/variables";

.header {
  border: 1px solid $dark-blue;
  border-radius: 2px 0;

  :global(.MuiTableCell-root) {
    border: 0;
    font-weight: bold;
  }
}

.body {
  :global(.MuiTableRow-root) {
    border: 1px $light-grey;
    border-style: none solid;

    :global(.MuiTableCell-root) {
      border-color: $light-grey;
      font-size: 16px;
    }
  }
}

.header,
.body {
  :global(.MuiTableCell-root) {
    font-family: "Open Sans", sans-serif;
  }
}

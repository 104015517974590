@use "sass:color";
@import "../../../styles/variables";

.area {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 65%;
}

.container {
  display: flex;
}

.label, .error {
  color: $white;
  font: 18px "Open Sans", sans-serif;
}

.error {
  display: flex;
  gap: 10px;

  span {
    font-size: 16px;
  }
}

.iconButton:global(.MuiIconButton-root) {
  background-color: $secondary-color;
  border-radius: 0;
  color: $white;
  width: 20%;

  &:hover {
    background-color: $secondary-color;
  }

  :global(.MuiSvgIcon-root) {
    font-size: 1.75rem;
  }
}

.input {
  &:global(.MuiTextField-root) {
    width: 80%;
  }

  :global(.MuiInputBase-root) {
    background-color: $white;
    border-radius: 0;
    color: $black-primary-color;
    font-family: "Open Sans", sans-serif;
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: 0;
  }

  :global(.Mui-error) {
    box-shadow: inset 0 0 0 2px #bd2624;
  }
}


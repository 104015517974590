@use "../../../styles/mixins" as mixins;
@import "../../../styles/variables";

.group {
  @include mixins.menuGroup;
}

.groupTitle {
  align-items: center;
  color: $secondary-color;
  cursor: pointer;
  display: inline-flex;
  font: bold 14px/19px "Open Sans", sans-serif;
  padding: 0.25rem 0 0 0.75rem;
  width: 100%;

  &_disabled {
    color: $light-grey;
    pointer-events: none;
  }
}

.collapsableList {
  :global(.MuiCollapse-wrapperInner) {
    display: flex;
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

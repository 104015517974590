@mixin cursor {
  cursor: default;
}

@mixin title($font-size, $line-height) {
  @include cursor;

  font-size: $font-size;
  line-height: $line-height;
}

.navigationTitle {
  @include cursor;

  padding: 0 24px 8px;
}

.pageTitle {
  @include title(24px, 40px);

  font-weight: normal;
}

.areaSubTitle {
  @include title(32px, 40px);

  margin-bottom: 1.5rem;
}

.contentTitle {
  @include title(18px, 32px);
}

.brandTitle {
  @include title(32px, 40px);
}

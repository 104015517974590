@use "../../../styles/mixins" as mixins;
@import "../../../styles/variables";

.section {
  @include mixins.clearedRichText;
}

.caution {
  border: 1px $red;
  border-style: solid none;
  padding: 1rem 0;
}
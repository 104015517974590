@import "../../../styles/variables";

.area {
  align-items: center;
  background-color: $dark-blue;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  min-height: 12.187rem;
}

@use "../../styles/mixins" as mixins;

.container > .list:first-of-type {
  padding-top: 0;
}

.list {
  @include mixins.borderBottom;

  display: flex;
  gap: 1.25rem;
  min-height: 64px;
  padding: 32px 0;
}

.adContainer {
  width: calc(100% - 106px - 1.25rem);

  div[id*="banner"] {
    text-align: center;

    iframe {
      margin-bottom: 32px;
      margin-left: -100px;
    }
  }
}

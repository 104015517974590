@import "../../styles/variables";

.group {
  break-inside: avoid;
  color: $black-primary-color;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
}

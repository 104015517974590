@use "../../styles/mixins" as mixins;
@import "../../styles/variables";

.symbolTabList {
  @include mixins.baseTabList;
  @include mixins.stickyPosition(#{$header-height});

  padding: 34px 0 58px;

  :global(.MuiButtonBase-root) {
    min-width: 32px;
    padding: 6px 8px;

    &:hover {
      font-weight: bold;
    }

    &:global(.Mui-disabled) {
      color: $light-grey;
    }
  }

  :global(.MuiTabs-flexContainer) {
    justify-content: center;
  }

  :global(.MuiTabScrollButton-root.Mui-disabled) {
    opacity: unset;
  }
}

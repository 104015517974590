@use "../../styles/mixins" as mixins;
@import "../../styles/variables";

@value symbolTabList from "../symbolTabs/SymbolTabs.module.scss";

%area-padding {
  padding: 32px 16px;
}

.tabList {
  @include mixins.baseTabList;
  @include mixins.borderBottom;
  @include mixins.stickyPosition(0);
}

.tabPanel {
  @include mixins.justify-symbol-tabs($width-collapsed);

  &:global(.MuiTabPanel-root) {
    padding: 0;

    &.areas {
      @extend %area-padding;

      column-count: 3;
    }

    &.searchAreas {
      @extend %area-padding;
    }

    &.area {
      padding: 1.5rem 0;
    }
  }
}

@import "../../styles/variables";

@value link from "./menuLink/MenuLink.module.scss";
@value link_active from "./menuLink/MenuLink.module.scss";

.drawer {
  :global(.MuiDrawer-paper) {
    background-color: $background-color;
    overflow-x: hidden;
    position: absolute;
    top: auto;
    width: 328px;

    &::-webkit-scrollbar {
      background-color: $background-color;
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: content-box;
      background-color: $scroll-thumb-color;
      border: 1px solid transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 6rem;
    }
  }

  &_closed :global(.MuiDrawer-paper) {
    overflow: hidden;
    pointer-events: none;
    /* stylelint-disable declaration-no-important */
    transform: translateX(-305px) !important;
    visibility: visible !important;
    /* stylelint-enable declaration-no-important */

    :global(.link_active) {
      background-color: $background-color;
    }

    :global(.link),
    :global(.link_active) {
      visibility: hidden;
    }
  }
}

.iconButton {
  &:global(.MuiIconButton-root) {
    background-color: $dark-blue;
    color: $white;
    left: 5px;
    position: fixed;
    top: 120px;
    transition: all 0.25s ease-in-out;
    z-index: 1201;

    &:hover {
      background-color: $dark-blue;
    }
  }

  &_close:global(.MuiIconButton-root) {
    left: 308px;
    transition: all 0.1s ease-in-out;
  }
}

.container {
  height: 100%;
  position: fixed;
}
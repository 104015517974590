@import "../../styles/variables";

.symbol {
  color: $black-secondary-color;
  cursor: default;
  font: bold 4rem "Open Sans", sans-serif;
  line-height: 1;
  min-width: 100px;
  padding-left: 6px;

  &_disabled {
    color: $light-grey;
  }
}

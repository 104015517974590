@use "../../styles/mixins";
@import "../../styles/variables";

@value symbolTabList from "../symbolTabs/SymbolTabs.module.scss";

.content {
  @include mixins.justify-symbol-tabs($width-collapsed);

  color: $black-primary-color;
  display: flex;
  flex-direction: column;
  margin: 26px 60px;
  min-height: calc(100vh - #{$header-height});
  position: relative;
  transition: all 225ms ease-in-out;
  width: stretch;

  &_expanded {
    @include mixins.justify-symbol-tabs($width-expanded);

    margin-left: 368px;
    transition: all 0.1s ease-in-out;
  }
}

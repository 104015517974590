@import "../../../styles/variables";

.contents {
  border: 1px solid $light-grey;
  border-radius: 2px;
  height: fit-content;
  padding: 1.5rem;
  position: sticky;
  top: 6.25rem;
  width: 100%;

  button {
    all: unset;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;

    .item {
      color: $secondary-color;
      cursor: pointer;
      padding-left: 12px;

      &:hover {
        font-weight: bold;
      }

      &_active {
        border-left: 4px solid $secondary-color;
        color: $black-secondary-color;
        font-weight: bold;
      }

    }
  }
}

@use "../../../styles/mixins" as mixins;
@import "../../../styles/variables";

.description {
  @include mixins.clearedRichText;
  @include mixins.styledRichText;

  background-color: $background-color;
  border: 1px solid $dark-blue;
  border-radius: 2px;
  color: $black-secondary-color;
  line-height: 24px;
  padding: 20px 24px 30px;
}

@import "../../../styles/variables";

.list {
  margin: 0;
  padding-inline-start: 1.5rem;
}

.container {
  color: $black-secondary-color;
  word-wrap: break-word;
}
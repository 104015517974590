@import "./variables";

@mixin tab {
  color: $secondary-color;
  font: normal 1.375rem "Open Sans", sans-serif;

  &:hover {
    font-weight: bold;
  }
}

@mixin tabSelected {
  color: $black-secondary-color;
  font-weight: bold;
}

@mixin disableHoverShifting {
  &:after {
    content: attr(data-text);
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
    visibility: hidden;
  }
}

@mixin baseTabList {
  :global(.MuiButtonBase-root) {
    @include tab;
    @include disableHoverShifting;

    text-transform: unset;

    &:global(.Mui-selected) {
      @include tabSelected;
    }

    &:global(.Mui-disabled) {
      color: $light-grey;
    }
  }

  :global(.MuiTabs-indicator) {
    background-color: $secondary-color;
    height: 0.25rem;
  }
}

@mixin borderBottom {
  border-bottom: 1px solid $light-grey;
}

@mixin stickyPosition($top) {
  background-color: $white;
  position: sticky;
  top: $top;
}

@mixin listItem {
  cursor: pointer;
  width: fit-content;
}

@mixin clearedRichText {
  p {
    margin: 0;
  }

  ul, ol {
    margin: 0;
    padding-inline-start: 1.2rem;
  }
}

@mixin styledRichText {
  table,
  tr {
    border: 1px solid $light-grey;
    border-collapse: collapse;
  }

  td {
    padding: 1rem;
  }

  figure {
    margin: 0.5rem 0;
    overflow-x: auto;
  }

  a {
    @include link;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

@mixin menuGroup {
  display: flex;
  flex-direction: column;

  &:first-of-type {
    padding-top: 1.5rem;
  }

  &:last-of-type {
    padding-bottom: 1.5rem;
  }
}

@mixin link {
  color: $secondary-color;
  text-decoration: underline;
  text-decoration-skip-ink: none;

  &:hover {
    color: $hovered-link;
  }

  &:active {
    color: $active-link;
  }

  &:hover,
  &:active {
    text-decoration-thickness: 3px;
  }
}

@mixin justify-symbol-tabs($max-width) {
  @media (max-width: $max-width) {
    :global(.symbolTabList) :global(.MuiTabs-flexContainer) {
      justify-content: start;
    }
  }
}

@mixin button {
  color: $secondary-color;
  font: normal 16px/20px "Open Sans", sans-serif;
  text-transform: none;
}
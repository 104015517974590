.container {
  column-gap: 1.5rem;
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: auto;
}

.navigation {
  display: flex;
  flex: 0 0 auto;
  min-width: 30%;
}

.sideAd {
  height: fit-content;
  position: sticky;
  top: 6.25rem;

  iframe {
    margin-left: 1.5rem;
  }
}
